import React,{useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './MyProfile.css';

const MyProfile = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'About me';
  });

  return (
    <Container className='myprofile-container' as='main'>
      <Row>
        <Col xs='10' md='12' lg='10'>
          <h1>{t('itsMe')}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs='10' md='12' lg='10'>
          <h2>{t('WhoAmI')}</h2>
        </Col>
      </Row>
      <Row as='section'>
        <Col
          xs='12'
          sm='10'
          md='4'
          lg='3'
          as='article'
          className='profile-description'
        >
          <p>{t('paragraph1')}</p>
          <p>{t('paragraph2')}</p>
        </Col>
        <Col
          xs='12'
          sm='10'
          md='6'
          lg='7'
          as='article'
          className='profile-main'
        >
          <p>{t('paragraph3')}</p>
          <p>{t('paragraph4')}</p>
          <p>{t('paragraph5')}</p>
          <p>{t('paragraph6')}</p>
          <p>{t('ps')}</p>
        </Col>
      </Row>
      <Row as='section' className='portfolio-link'>
        <Col xs='12'>
          {/* <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://interactive-resume-2753d.web.app/'
          >
            Previous version of my portfolio build with AngularJS long time ago
          </a> */}
        </Col>
      </Row>
    </Container>
  );
};

export default MyProfile; 