import React from 'react';
import { useTranslation } from 'react-i18next';

import './SideMenu.css';

const Search = props => {
  const { t } = useTranslation();

  return (
    <aside>
      <ul className='side-menu'>
        <a href='\'>
          <li className='menu-portfolio menu-selected'>{t('portfolio')}</li>
        </a>
        <a href='\skills'>
          <li className='menu-skills'>{t('skills')}</li>
        </a>
        <a href='\myprofile'>
          <li className='menu-profile'>{t('profile')}</li>
        </a>
      </ul>
    </aside>
  );
};

export default Search;