import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        'navigator',
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
      ],
      caches: ['localStorage', 'cookie'],
    },
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
    // lng: 'en',
    fallbackLng: 'en',
    supportLngs: ['en', 'es'],
    nonExplicitSupportLngs: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
